<template>
  <div class="bigScreen">
    <div class="main-top">
      <h1 class="du_jiao">
        泰坦云控SCRM<br/>
        <div class="du_jiao_bottom">
          帮助您<span style="color: rgb(63, 140, 255)">工作、交友</span
        >的贴心助手
        </div>
      </h1>
      <div
          style="
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
        "
      >
        <div class="Rectangle">
          <div style="text-align: center; margin-top: 13%">
            <img src="@/assets/img/icon1.png" class="RectangleImg" alt=""/>
            <p class="tit">聚合多平台</p>
            <p class="ma">聚合多款海外热门社交软件，让您的时间更自由</p>
          </div>
        </div>
        <div class="Rectangle">
          <div style="text-align: center; margin-top: 13%">
            <img src="@/assets/img/icon2.png" class="RectangleImg" alt=""/>
            <p class="tit">智能翻译</p>
            <p class="ma">
              多国语言智能翻译，再也没有交流障碍，让您的沟通更方便
            </p>
          </div>
        </div>
        <div class="Rectangle">
          <div style="text-align: center; margin-top: 13%">
            <img src="@/assets/img/icon3.png" class="RectangleImg" alt=""/>
            <p class="tit">安全私密</p>
            <p class="ma">
              平台不涉及用户的社交账号数据，做到端对端加密，让您的使用更安心
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="main-top1">
      <h1
          style="font-weight: bold; font-size: 10em; color: rgb(211, 241, 255)"
          class="rjts"
      >
        软件特色
      </h1>
      <h3
          style="
          font-weight: 600;
          font-size: 48px;
          color: rgb(34, 42, 65);
          margin-top: -8%;
        "
          class="jh"
      >
        聚合了众多热门社交软件
      </h3>
      <p
          style="font-size: 22px; color: rgb(109, 119, 149)"
          class="shaw shawBox"
      >
        综合了Whatsapp、Facebook、Telegram等多款社交软件，随时切换聊天渠道，每个渠道支持多开账号。
      </p>
      <!-- <div class="service-p">
        <img
            src="@/assets/img/lianxi.png"
            style="cursor: pointer"
            @click="contachMe"
        />
      </div> -->
      <!-- <div class="service-p1">
        <img
            src="@/assets/img/64down.png"
            @click="downZip"
            style="cursor: pointer"
        />
      </div> -->

      <div class="service-p2">
        <img
            src="@/assets/img/gfcx.png"
            @click="openSearch"
            style="cursor: pointer"
        />
      </div>
      <div style="display: flex; flex-direction: row; justify-content: center">
        <p style="margin-top: 8%; margin-right: 20px">
          <img src="@/assets/img/whatsapp.png" class="commIcon" alt=""/>
        </p>
        <p style="margin-right: 30px">
          <img src="@/assets/img/telegram.png" class="commIcon" alt=""/>
        </p>
        <p style="margin-top: 6%; margin-right: 30px">
          <img src="@/assets/img/twitter.png" alt="" class="commIcon"/>
        </p>
        <p style="margin-top: 4%; margin-right: 20px">
          <img src="@/assets/img/facebook.png" alt="" class="commIcon"/>
        </p>
        <!-- <p style="margin-top: 10%">
          <img src="@/assets/img/zalo.png" alt="" class="commIcon" />
        </p> -->
      </div>
      <p>
        <img
            src="@/assets/img/bg_login.png"
            alt=""
            style="height: 706px"
            class="pcI"
        />
      </p>
    </div>

    <div
        style="display: flex; flex-direction: row; justify-content: center"
        class="main-top2 sxTopOne"
    >
      <img
          src="@/assets/img/bg_login_2.png"
          alt=""
          style="height: 706px"
          class="pcI"
      />
      <div style="margin-top: 10%; width: 30%" class="sxTop">
        <p
            style="
            font-size: 48px;
            color: rgb(34, 42, 65);
            line-height: 60px;
            text-align: left;
            font-weight: 600;
          "
            class="shaw_tit"
        >
          双向智能翻译,多国语种任你使用
        </p>
        <p
            style="
            font-size: 1.375em;
            color: rgb(109, 119, 149);
            line-height: 30px;
            text-align: left;
          "
            class="shaw"
        >
          您只需输入您熟悉的言语，平台就能翻译成您指定的任何语言，自动检测，手动设置，让您在海外的沟通不存在语言障碍。
        </p>
      </div>
    </div>

    <div
        style="margin-top: -4%; display: flex; flex-direction: row"
        class="main-top3"
    >
      <div style="width: 30%; margin-left: 10%; padding-top: 10%" class="sxTop">
        <p
            style="
            font-size: 48px;
            color: rgb(34, 42, 65);
            line-height: 60px;
            text-align: left;
            font-weight: 600;
          "
            class="shaw_tit"
        >
          保护用户隐私,绝对端加密保护
        </p>
        <p
            style="
            font-size: 1.375em;
            color: rgb(109, 119, 149);
            line-height: 30px;
            text-align: left;
          "
            class="shaw"
        >
          平台保护用户个人隐私数据，使用端对端加密保护手段，保证用户的数据安全，同时平台也无法查看，无法保存用户的聊天信息，为用户打造一个安全放心的聊天空间。
        </p>
      </div>
      <div class="pcIbig">
        <img
            src="@/assets/img/home_bg1.png"
            alt=""
            style="height: 706px"
            class="pcI"
        />
      </div>
    </div>

    <div class="main-top4">
      <h1
          style="
          font-weight: bold;
          font-size: 10em;
          color: rgb(211, 241, 255);
          margin-top: -3%;
        "
          class="downT"
      >
        软件下载
      </h1>
      <p style="margin-top: -8%">
        <img src="@/assets/img/logo314.png" alt="" class="downIcon"/>
      </p>
      <div>
        <img
            style="cursor: pointer"
            src="@/assets/img/godownload.png"
            @click="downZip"
            class="downButton"
        />
      </div>
    </div>
    <el-dialog
        title="官方查询"
        :visible.sync="dialogSearchVisible"
        class="tipBoxX"
        width="450px"
        @close="handleSearchClose"
    >
      <div class="gfcxBox">
        <el-select v-model="value" placeholder="请选择" style="width: 300px;">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          >
          </el-option>
        </el-select>
        <el-input
            class="gfcxInput"
            v-model="inputKeyWord"
            placeholder="请输入内容"
        >
          <i class="el-icon-search el-input__icon" slot="prefix"> </i>
        </el-input>
        <el-button type="primary" @click="search">查询</el-button>
      </div>
      <div class="subTitle">温馨提示</div>
      <div class="ant-card-body">
        <div style="padding: 48px 32px" v-if="initSearchType == 1">
          <img
              v-if="initSearchType"
              class="okImg"
              src="@/assets/img/ic_check.png"
          />
          <div class="ant-result-title">泰坦云控APP官方渠道</div>
          <div class="ant-result-subtitle">
            您查询 {{ currentKeyWord }} 为泰坦云控APP官方渠道。
          </div>
        </div>

        <div style="padding: 48px 32px" v-if="initSearchType == 2">
          <img
              v-if="initSearchType"
              class="okImg"
              src="@/assets/img/ic_close.png"
          />
          <div class="ant-result-title">非泰坦云控APP官方渠道</div>
          <div class="ant-result-subtitle">
            您查询 {{ currentKeyWord }}
            为非泰坦云控APP官方渠道，请注意您的资产安全，保护好您的私人信息。
          </div>
        </div>
        <p v-if="initSearchType == 0">
          为防止不法分子借用泰坦云控APP官方名义进行诈骗，您可通过此页面确认联系您的客服人员等是否为泰坦云控APP官方渠道。
        </p>
      </div>
    </el-dialog>
    <el-dialog
        title="《系統公告》"
        :visible.sync="dialogVisible"
        width="60%"
        class="tipBox"
        @close="handleClose"
    >
      <div class="tipItem">
        1.泰坦云控im不支援中國大陸客戶使用，禁止中國大陸IP訪問，請中國大陸用戶自覺卸載停止使用泰坦云控im的系列產品。
      </div>
      <div class="tipItem">
        2.泰坦云控im不提供帳號交易、ws群發、廣告代發、買賣數據、支付擔保、代收代付等服務，任何以泰坦云控im名義提供以上服務的均為騙子！
      </div>
      <div class="tipItem">
        3.泰坦云控im作為全球軟體SCRM工具，僅為全球用戶提供正常的軟體產品服務，請勿使用泰坦云控im產品發布虛假訊息，從事任何違法犯罪活動。
      </div>
      <div class="tipItem">
        4、任何用戶違反以上要求的，泰坦云控im有權立即停止產品服務授權
      </div>
    </el-dialog>
  </div>
</template>

<script>

import {getDicts} from "@/api/main/admin";

export default {
  data() {
    return {
      dialogVisible: false,
      tipWidth: "30%",
      screenWidth: 0,
      dialogSearchVisible: false,
      options: [
        {
          value: "Telegram",
          label: "Telegram",
        },
      ],
      value: "Telegram",
      initSearchType: "0", //0正常 1正确 2错误
      tgAccount: [],
      inputKeyWord: "",
      currentKeyWord: '',
    };
  },
  mounted() {
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      console.log("高度");
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
        this.init();
      })();
    };
  },
  async created() {
    getDicts("tg_account").then(res => {
      this.tgAccount = res.data
    }).catch(error => {
    })
    this.screenWidth = document.body.clientWidth;
    this.init();
    if (localStorage.getItem("tipAlreadyOpen") != "close") {
      this.dialogVisible = true;
    }
  },
  methods: {
    search() {
      this.initSearchType = 2;
      for (let i = 0; i < this.tgAccount.length; i++) {
        if (this.tgAccount[i].dictValue == this.inputKeyWord) {
          this.initSearchType = 1;
          break;
        }
      }
      this.currentKeyWord = this.inputKeyWord
    },
    handleSearchClose() {
    },
    openSearch() {
      this.dialogSearchVisible = true;
    },
    handleClose() {
      localStorage.setItem("tipAlreadyOpen", "close");
    },
    init() {

      if (this.screenWidth > 992) {
        this.tipWidth = "30%";
      } else {
        this.tipWidth = "90%";
      }
    },
    downZip() {
      this.$router.push({ path: '/download' });
    },
    contachMe() {
      for (let i = 0; i < this.tgAccount.length; i++) {
        if (this.tgAccount[i].dictLabel == 'kefu') {
          window.open("https://t.me/"+this.tgAccount[i].dictValue);
          break;
        }
      }
      
    },
  },
  beforeDestroy() {
  },
};
</script>

<style lang="scss" scoped>
.main-top {
  background-image: url(@/assets/img/backimg1.png);
  background-size: 100% 711px;
  height: 711px;
  width: 100%;
  background-repeat: no-repeat;
}

.bigScreen {
  flex: 1;
  color: #333;
  text-align: center;
  margin-top: -78px;
  width: 100%;
  background-color: #fff;
}

.Rectangle {
  background-image: url(@/assets/img/Rectangle.png);
  height: 336px;
  width: 342px;
}

.ma {
  font-size: 18px;
  color: #6d7795;
  font-weight: 400;
  font-family: PingFangSC-Regular, PingFang SC;
  width: 290px;
  line-height: 27px;
  margin-left: 25px;
}

.main-top1,
.main-top2 {
  background-size: 100% 100%;
  width: 100%;
  background-repeat: no-repeat;
}

.main-top1 {
  background-image: url(@/assets/img/bg_login_3.png);
  // height: 100%;
}

.service-p,
.service-p1,
.service-p2 {
  position: fixed;
  height: 39px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.service-p1 {
  bottom: 300px;
  right: 50px;
  width: 128px;
}

.service-p2 {
  bottom: 160px;
  right: 50px;
  width: 128px;
}

.service-p {
  bottom: 220px;
  right: 13px;
  width: 200px;
}

.main-top2 {
  background-image: url(@/assets/img/backimg2.png);
}

.main-top3 {
  background-image: url(@/assets/img/bg_login_4.png);
  height: 800px;
}

.main-top3,
.main-top4 {
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.du_jiao {
  font-size: 4.5em;
  padding-top: 5%;
}

.tit {
  font-size: 30px;
  font-weight: 600;
  margin-top: 10px;
  color: rgb(34, 42, 65);
  line-height: 40px;
}

.tipItem {
  text-align: left;
  margin-bottom: 10px;
}

.gfcxBox {
  display: flex;

  .gfcxInput {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.subTitle {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ant-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  --antd-wave-shadow-color: #1890ff;
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
  font: 14px/1.6 PingFang SC, Microsoft Yahei, Hiragino Sans GB, sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
  Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
  sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  font-feature-settings: "tnum";
  user-select: none;
  box-sizing: border-box;
  zoom: 1;
  padding: 15px;
  border: rgba(0, 0, 0, 0.05) solid 1px;

  .okImg {
    width: 80px;
    height: 80px;
    margin-bottom: 20px;
  }

  .ant-result-title {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    --antd-wave-shadow-color: #1890ff;
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
    font: 14px/1.6 PingFang SC, Microsoft Yahei, Hiragino Sans GB, sans-serif;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    user-select: none;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.85);
    font-size: 24px;
    line-height: 1.8;
    text-align: center;
  }

  .ant-result-subtitle {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    --antd-wave-shadow-color: #1890ff;
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
    font: 14px/1.6 PingFang SC, Microsoft Yahei, Hiragino Sans GB, sans-serif;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: "tnum";
    user-select: none;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: 1.6;
    text-align: center;
  }
}

@media (min-width: 992px) {
  .pcIbig {
    width: 70%;
    margin-left: 5%;
    height: auto !important;
  }
}

@media (max-width: 991px) {
  .service-p,
  .service-p1,
  .service-p2 {
    display: none;
  }
  .bigScreen {
    margin-top: -20px;
  }
  .main-top {
    width: 100%;
    background-size: 100% 100%;
    padding-bottom: 55px;
    height: auto !important;
  }
  .du_jiao {
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    padding-top: 70px;
    font-family: FZHZGBJW--GB1-0, FZHZGBJW--GB1;
    color: #222a41;
    text-align: center;
  }
  .du_jiao_bottom {
    font-size: 26px;
    font-weight: 700;
    padding-top: 20px;
  }
  .Rectangle {
    background: hsla(0, 0%, 100%, 0.6);
    border-radius: 8px;
    border: 1px solid #fff;
    padding: 10px;
    width: 24%;
    height: auto;
  }
  .RectangleImg {
    width: 30px;
    height: 30px;
  }

  .tit {
    height: 18px;
    font-size: 13px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #0d273c;
    line-height: 18px;
    margin-top: 6px;
    margin-bottom: 5px;
  }
  .ma {
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6d7795;
    line-height: 18px;
    height: 82px;
    width: auto;
    text-align: center;
    margin: 0;
  }
  .rjts {
    font-size: 35px !important;
  }
  .jh {
    margin-top: 10px !important;
    font-size: 20px !important;
  }
  .shaw {
    font-size: 13px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6d7795 !important;
    line-height: 26px;
    margin: 5px 38px;
  }
  .commIcon {
    width: 50px !important;
    height: 50px !important;
  }
  .pcI {
    width: 331px;
    height: auto !important;
  }
  .main-top3,
  .main-top2 {
    flex-direction: column !important;
    align-items: center;
  }
  .shaw_tit {
    font-size: 16px !important;
    font-family: FZDHTK--GBK1-0, FZDHTK--GBK1;
    font-weight: 700 !important;
    color: #0d273c;
    line-height: 32px !important;
    margin-top: 23px !important;
    text-align: center !important;
    width: 100% !important;
  }

  .shaw {
    font-size: 13px !important;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400 !important;
    color: #6d7795 !important;
    line-height: 26px !important;
    margin: 5px 0px !important;
    width: 100% !important;
  }
  .sxTop {
    margin-left: 0px !important;
    margin-top: 0px !important;
    width: 80% !important;
  }
  .sxTopOne {
    flex-direction: column-reverse !important;
  }
  .shawBox {
    width: 80% !important;
    margin-left: 10% !important;
  }
  .downT {
    font-size: 64px !important;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600 !important;
    color: #d3f1ff;
    line-height: 88px !important;
    margin: 57px 0 22px !important;
  }
  .main-top3 {
    margin-bottom: 0px !important;
    height: auto !important;
  }
  .main-top3,
  .main-top2,
  .main-top1 {
    background-image: none !important;
  }
  .downIcon {
    width: 120px;
    height: 120px;
    margin-top: 20px;
  }
}
</style>
<style>
.tipBox .el-dialog__body {
  padding-left: 30px;
  padding-right: 30px;
}

.tipBoxX .el-dialog__body {
  padding-top: 10px;
}

.tipBoxX {
  display: flex;
  align-items: center;
}
</style>
