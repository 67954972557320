export default {
  namespaced: true,
  state: {
    userInfoFirst: {},
    // 用户权限信息
    userInfo: {}
  },
  mutations: {
    /**
     * @description 切换激活状态
     * @param {Object} state vuex state
     */
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    setUserInfoFirst(state, userInfoFirst) {
      state.userInfoFirst = userInfoFirst
    },
    clearAll(state) {
      state.userInfoFirst = {}
      state.userInfo = {}
    },
  },
  actions: {
    setUserInfo({ commit }, userInfo) {
      commit('setUserInfo', userInfo)
    },
    setUserInfoFirst({ commit }, userInfoFirst) {
      commit('setUserInfoFirst', userInfoFirst)
    },
    clearAll({ commit }) {
      commit('clearAll')
    }
  }
}
