import axios from 'axios'
import router from '@/router/index'
import {
	Message
} from 'element-ui'
import loading from '@/libs/loading'
import message from '@/libs/message'
import store from '@/store';
var requesturl = 'zzjserver/controlAction.do?method=ApplyAction';

// 记录和显示错误
function errorLog(err) {
	// 打印到控制台
	// 显示提示
	Message({
		message: err.message,
		type: 'error',
		duration: 5 * 1000
	})
}

// 创建一个 axios 实例
const service = axios.create({
	url: requesturl,
	timeout: 20000 // 请求超时时间
})

// 请求拦截器
service.interceptors.request.use(
	config => {
		if (config.url === '/web/a/project/district/getDPProjectNum') {
			console.log(config.params.officeCode)
			if (store.state.user.userInfo.roleCode === "jld" || store.state.user.userInfo.roleCode === "sjbm_ld" || store.state.user.userInfo.roleCode === "sjbm") {
				if(config.params  && config.params.hasOwnProperty('officeCode')){
					delete config.params.officeCode
				
				}
				if(config.data  &&config.data.hasOwnProperty('officeCode')){
					delete config.params.officeCode
					
				}
			}
			
		}
		if (config.url === '/web/a/project/district/getDPProjectNum') {
			console.log(config.params.officeCode)
			if (store.state.user.userInfo.roleCode === "jld" || store.state.user.userInfo.roleCode === "sjbm_ld") {
				if(config.params  && config.params.hasOwnProperty('qxType')){
					config.params.qxType = 0
				
				}
				if(config.data  &&config.data.hasOwnProperty('qxType')){
					config.data.qxType = 0
					
				}
			}
			
		} else {
			if (config.params && config.params.hasOwnProperty('officeCode')&&config.url !== '/web/question/newList' &&
			config.url!=='/project/findOfficeQuestionProjectNum') {
				if (store.state.user.userInfo.roleCode === "sjbm_ld" || store.state.user.userInfo.roleCode === "ywbm_ld" ||
					store.state.user.userInfo.roleCode === "sjbm" || store.state.user.userInfo.roleCode === "jbbm1"
					|| store.state.user.userInfo.roleCode === "jld") {
					delete config.params.officeCode
				}
			}
			if (config.params && config.params.hasOwnProperty('officeCode')) {
				if ((store.state.user.userInfo.roleCode === "ywbm_ld" ||
					store.state.user.userInfo.roleCode === "xmdw_yw") && config.params.hasOwnProperty('qxType')) {
					config.params.qxType = 1
				}
				else if (config.params.hasOwnProperty('qxType') && store.state.user.userInfo.roleCode === "jld") {
					config.params.qxType = 0
				}
			}
			if (config.params && config.params.hasOwnProperty('officeName') && config.url !== "/web/dpProject/update" &&
				config.url !== "/web/dpProject/getListByOfficeCode" && config.url !== "/web/dpProject/add" &&
				config.url!=='/project/findOfficeQuestionProjectNum') {
				if (store.state.user.userInfo.roleCode === "sjbm_ld" ||
					store.state.user.userInfo.roleCode === "sjbm" || store.state.user.userInfo.roleCode === "jbbm1"
					|| store.state.user.userInfo.roleCode === "jld") {
					delete config.params.officeName
				}
			}


			if (config.data && config.data.hasOwnProperty('officeCode')&&config.url !== '/web/question/newList') {
				if (store.state.user.userInfo.roleCode === "sjbm_ld" || store.state.user.userInfo.roleCode === "ywbm_ld" ||
					store.state.user.userInfo.roleCode === "sjbm" || store.state.user.userInfo.roleCode === "jbbm1"
					|| store.state.user.userInfo.roleCode === "jld") {
					delete config.data.officeCode
				}
			}
			if (config.data && config.data.hasOwnProperty('officeCode')) {
				if ((store.state.user.userInfo.roleCode === "ywbm_ld" ||
					store.state.user.userInfo.roleCode === "xmdw_yw") && config.data.hasOwnProperty('qxType')) {
					config.data.qxType = 1
				}
				else if (config.data.hasOwnProperty('qxType') && store.state.user.userInfo.roleCode === "jld") {
					config.data.qxType = 0
				}
			}
			if (config.data && config.data.hasOwnProperty('officeName') &&
				config.url !== "/web/dpProject/getListByOfficeCode") {
				if (store.state.user.userInfo.roleCode === "sjbm_ld" ||
					store.state.user.userInfo.roleCode === "sjbm" || store.state.user.userInfo.roleCode === "jbbm1"
					|| store.state.user.userInfo.roleCode === "jld") {
					delete config.data.officeName
				}
			}
			if (config.params && config.params.hasOwnProperty('qxType')) {
				if (store.state.user.userInfo.roleCode === "jld") {
					config.params.qxType = 0
				} else {
					config.params.qxType = 1
				}

			}
			if (config.data && config.data.hasOwnProperty('qxType')) {
				if (store.state.user.userInfo.roleCode === "jld") {
					config.data.qxType = 0
				} else {
					config.data.qxType = 1
				}

			}
		}
		loading.show(config)
		return config
	},
	error => {
		// 发送失败
		Promise.reject(error)
	}
)

// 响应拦截器
service.interceptors.response.use(
	response => {
		loading.hide(response.config)
		const res = response.data
		if (response.status !== 200) {
			Message({
				message: res.message,
				type: 'error',
				duration: 3 * 1000
			})
			return Promise.reject(res.message)
		} else if (res.code == 400) {
			// 显示提示
			Message({
				message: res.message,
				type: 'error',
				duration: 5 * 1000
			})
		} else {
			message(response.config)
			return res
		}
	},
	error => {
		loading.hide(error.config)
		errorLog(new Error(` ${error.response.data.message} !: ${error.config.url}`))
		return Promise.reject(error)
	}
)

export default service
