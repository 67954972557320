import Vue from 'vue'
import Vuex from 'vuex'

import user from './modules/user'
import moreModal from './modules/moreModal'
import bigScreen from './modules/bigScreen'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)
const dataPersistence = new VuexPersistence({
  storage: window.localStorage
})
export default new Vuex.Store({
  modules: {
    user,
    moreModal,
    bigScreen
  },
  plugins: [dataPersistence.plugin]
})
