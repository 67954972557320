<template>
  <div id="app" style="width:100vw;">
    <div style="display: flex; position: relative;width:100vw;">
      <common-top-box />
    </div>
    <div
      style="
        flex: 1;
        overflow-y: auto;
        width: 100%;
      "
      class="mainBoxVue"
    >
      <router-view />
      <common-bottom-box />
    </div>
  </div>
</template>       
<script>
import CommonBottomBox from "./components/CommonBottomBox.vue";
import CommonTopBox from "./components/CommonTopBox.vue";
export default {
  components: { CommonTopBox, CommonBottomBox },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>


<style lang="scss">
@import "./assets/scss/variables";
.mainBoxVue{
  display: flex;
  flex-direction: column;
}
.bacDashboardBox {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  right: 2px !important;

  .bacDashboard {
    width: 2.2rem;
    height: 2.2rem;
    cursor: pointer;
    z-index: 10;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #fff !important;
  display: flex;
  flex-direction: column;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
.commonRectDiv {
  width: 15px;
  height: 15px;
  background-color: #29d3b1;
  margin-right: 10px;
}

.cell {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.commonItemTitle {
  background-size: 100% 100%;
  width: 90%;
  margin-left: 5%;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: left;
  position: relative;
  .commonItemTitleText {
    height: 100%;
    padding-top: 40px;
    margin-left: 40px;
  }
  .commonItemTitleMore {
    position: absolute;
    right: 10px;
    cursor: pointer;
  }
}
.divLeft {
  margin-left: 56px !important;
}
.divRight {
  margin-left: 60px !important;
}
.content-box-border-one {
  background-size: 100% 100%;
}

.el-table .cell {
  // text-overflow: -o-ellipsis-lastline;
  // overflow: hidden;
  // text-overflow: ellipsis;
  // display: -webkit-box;
  // -webkit-line-clamp: 3;
  // -webkit-box-orient: vertical;
}
.treeClassItemTitle {
  color: rgb(54, 137, 194) !important;
}
.posTitle {
  margin-top: 10px;
}
.treeClassItemTitle {
  text-align: left;
  padding-left: 5px;
}
.commonBtnStyle {
  background-color: rgb(197, 240, 254) !important;
  color: black !important;
  border: none !important;
  font-size: 18px !important;
}

.groupItem {
  background-color: rgb(197, 240, 254) !important;
  color: black !important;
  border: none !important;
}

.groupItemClick {
  background-color: #0066ff !important;
  color: white !important;
  border: none !important;
}

.commonTableBox {
  background-size: 100% 100% !important;
  position: relative !important;
  background-repeat: no-repeat !important;
  padding: 30px !important;
  margin-bottom: 2.5rem !important;
  width: calc(100vw - 32rem) !important;
  margin: 20px !important;
  overflow-y: auto;
}

.commonLeftBox {
  flex-direction: column;
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
}

.commonRightBoxSpecial {
  flex-direction: column;
  background-size: 100% 100%;
  position: relative;
  background-repeat: no-repeat;
  overflow-y: auto;
}

.treeClassInner {
  background-color: $ItemBoxBackgroudColor;
  height: calc(100% - 3.2375rem);
  width: calc(100% - 2.5rem);
  margin-top: 0.9375rem;
  margin-bottom: 0.225rem;
  border-radius: 0.225rem;
  overflow-y: auto;
}
.commonTreeClassInnerBacColor {
  background-color: $ItemBoxBackgroudColor;
  border-radius: 0.625rem;
}
.treeClassInnerBox {
  display: flex;
  justify-content: center;
  align-items: center;
}
.controlBtnTableDiv {
  width: 100%;
  display: flex !important;
  justify-content: center;
}
.controlBtnTablenNumber {
  width: 100%;
  display: flex;
  justify-content: left;
}
.tableClass {
  height: 100%;
}
.borderSplit {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.tableTopBoxInputBox .el-button {
  position: absolute;
  width: 12.0625rem;
  height: 3.5rem;
  background: #5470c6;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 18px 18px 18px 18px;
  right: calc(50% - 27.90625rem);
}
.el-dialog__title {
  font-size: 22px;
  font-weight: 500;
}

.tableClose {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 19px;
  height: 19px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}
.el-button--success {
  background-color: #4aac94 !important;
  border-color: #4aac94 !important;
}

.el-table tr:hover {
  background-color: rgba(74, 172, 148, 0.7) !important;
  border: none !important;
}

.el-table td {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
}
.overFlowPoint {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  display: -webkit-box;
}
.choseInputLeft {
  align-items: center;
  display: flex;
  margin-right: 10px;
  font-size: 1.2rem;
}
.groupCodeStrClass {
  max-width: 180px !important;
}
.commonInputClass {
  margin-bottom: 4px;
  margin-top: 4px;
}

</style>
