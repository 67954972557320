export default {
  namespaced: true,
  state: {
    dpProject:{},
    mainTitle:'项目审计管理系统'
  },
  mutations: {
    /**
     * @description 切换激活状态
     * @param {Object} state vuex state
     */
     setDpProject(state, dpProject) {
      state.dpProject = dpProject
    },
    setMainTitle(state, mainTitle) {
      state.mainTitle = mainTitle
    }
  },
  actions: {
    setDpProject({ commit }, dpProject) {
      commit('setDpProject', dpProject)
    },

    setMainTitle({ commit }, mainTitle) {
      commit('setMainTitle', mainTitle)
    }
  }
}
