import request from '@/plugin/axios'

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
    return request({
        url: '/system/dict/data/type/' + dictType, method: 'get'
    })
}

export function dataFilling() {
    return request({
        url: '/scrm/downloadPath/latest',
        method: 'get'
    })
}

//
// export default {
//     //登陆
//     login: function (params) {
//         return request({
//             url: '/web/a/login',
//             method: 'post',
//             params
//         })
//     },
//     getTgAccount: function (params) {
//         return request({
//             url: '/system/dict/data/type/tg_account',
//             method: 'get',
//             params
//         })
//     },
//     //获取用户权限信息
//     getUserCode: function (params) {
//         return request({
//             url: '/web/getUserCode',
//             method: 'post',
//             params
//         })
//     },
//     //登出
//     logout: function (params) {
//         return request({
//             url: '/web/a/logout',
//             method: 'get',
//             params
//         })
//     },
//     //bigscreen 进来传usercode 调用
//     webLmls: function (params) {
//         return request({
//             url: '/web/lmls',
//             method: 'get',
//             params
//         })
//     }
// }

