<template>
  <div data-v-adff3d9a="" class="price-box">
    <div  class="mainC">
      <img src="@/assets/img/ic_price.png" class="mainImg" />
      <div class="mainInner">
        <div class="price-box-tit">泰坦云控SCRM</div>
        <div style="height: 60px;"></div>
        <!-- <div class="price-box-button" @click="buy">点击购买</div> -->
        <div class="price-box-model row wrap">
          <div class="row rowS" style="align-items: center; width: 330px">
            <img src="@/assets/img/icon5.png" />
            <span class="price-box-span">客户端独立邀请码统一管理</span>
          </div>
          <div class="row rowS" style="align-items: center">
            <img src="@/assets/img/icon6.png" />
            <span class="price-box-span">实时翻译不限字符数</span>
          </div>
          <div class="row rowS" style="align-items: center; width: 330px">
            <img src="@/assets/img/icon7.png" />
            <span class="price-box-span">多社交平台聚合一体化客户端</span>
          </div>
          <div class="row rowS" style="align-items: center">
            <img src="@/assets/img/icon8.png" />
            <span class="price-box-span">智能联系人统计</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getDicts} from "@/api/main/admin";
export default {
  data() {
    return {
      tgAccount: [],
    };
  },
  mounted() {},
  async created() {
    
    getDicts("tg_account").then(res => {
      this.tgAccount = res.data
    }).catch(error => {
    })
  },
  methods: {
    buy() {
      for (let i = 0; i < this.tgAccount.length; i++) {
        if (this.tgAccount[i].dictLabel == 'kefu') {
          window.open("https://t.me/"+this.tgAccount[i].dictValue);
          break;
        }
      }
    },
  },
  beforeDestroy() {},
};
</script>

<style lang="scss" scoped>
.priceImg {
  background: url(@/assets/img/ic_price.png) no-repeat;
}
.price-box {
  background: url(@/assets/img/bg1.png) no-repeat;
  //background-image: url();
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  background-size: 100% 100%;
}
.price-box-tit {
  height: 87px;
  font-size: 72px;
  font-family: FZHZGBJW--GB1-0, FZHZGBJW--GB1;
  font-weight: 700;
  color: #222a41;
  line-height: 87px;
  text-align: left;
}
.price-box-button {
  width: 200px;
  height: 60px;
  background: #3f8cff;
  box-shadow: 2px 8px 20px 0 rgba(58, 137, 255, 0.2);
  border-radius: 30px;
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #fff;
  line-height: 60px;
  margin: 40px 0;
  cursor: pointer;
}
.price-box-model {
  width: 600px;
  height: 150px;
  background: hsla(0, 0%, 100%, 0.3);
  border-radius: 16px;
  border: 2px solid #fff;
  padding: 30px 19px;
  flex-wrap: wrap;
  display: flex;
}
.row {
  display: flex;
}
.price-box-span {
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6d7795;
  line-height: 18px;
  margin-left: 8px;
}
.mainC {
  padding-bottom: 40px;
  flex: 1;
  display: flex;
  justify-content: space-around;
  margin-left: auto;
}
.mainImg {
  width: 320px;
  height: 480px;
}
.mainInner {
}

@media (min-width: 992px) {
  .mainC {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    margin-right: 15%;
    margin-top: 100px;
    position: relative;
  }
}

@media (min-width: 992px) and (max-width: 1561px) {
  .mainC {
    margin-right: auto;
    padding-top: 50px;
  }
}

@media (max-width: 991px) {
  .price-box {
    background: linear-gradient(
      1turn,
      rgba(228, 247, 255, 0),
      #e4f7ff 11%,
      #e4f7ff
    ) !important;
    background-image: none;
  }
  .mainC {
    margin-left: 0px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px !important;
    line-height: 20px !important;
    padding-bottom: 10px !important;
    height: 100%;
  }
  .price-box-tit {
    font-size: 32px !important;
    font-family: FZHZGBJW--GB1-0, FZHZGBJW--GB1;
    font-weight: 700 !important;
    color: #222a41 !important;
    line-height: 39px !important;
    padding: 41px 0 10px !important;
    height: auto !important;
    text-align: center !important;
  }
  .price-box-button {
    width: 140px !important;
    height: 36px !important;
    background: #166def;
    box-shadow: 2px 8px 20px 0 rgba(58, 137, 255, 0.2) !important;
    border-radius: 30px !important;
    margin: 30px auto !important;
    font-size: 14px !important;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600 !important;
    color: #fff !important;
    line-height: 36px !important;
  }
  .price-box-model {
    position: relative;
    display: block;
    border: none !important;
    background: none !important;
    height: auto !important;
  }
  .rowS {
    width: 300px !important;
    height: 62px;
    background: linear-gradient(
      90deg,
      hsla(0, 0%, 100%, 0.6),
      hsla(0, 0%, 100%, 0.8)
    );
    border-radius: 16px;
    border: 2px solid #fff;
    margin: 0 auto;
    padding: 0 15px;
    margin-bottom: 10px;
  }
  .price-box-span {
    font-size: 14px !important;
  }
  .price-box-model {
    width: auto !important;
  }
}
</style>
