export default {
  namespaced: true,
  state: {
    // 用户信息
    moreModalShow: false,
    data: [],
    title:''
  },
  mutations: {
    /**
     * @description 切换激活状态
     * @param {Object} state vuex state
     */
    setModalShowState(state, moreModalShow) {
      state.moreModalShow = moreModalShow
    },
    setModalTitle(state, title) {
      state.title = title
    },
    setData(state, data) {
      state.data = data
    }
  },
  actions: {
    setModalShowState({ commit }, moreModalShow) {
      commit('setModalShowState', moreModalShow)
    },
    setData({ commit }, data) {
      commit('setData', data)
    },
    setModalTitle({ commit }, title) {
      commit('setModalTitle', title)
    }
  }
}
