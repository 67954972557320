<template>
  <div class="common">
    <div class="common-t">
        <p style="margin-top: 30px; display: inline-block;text-align:left;width:100%;" class="cj">
          免责声明
        </p>
      <p class="common-tm">
        欢迎您使用titan.me网站（以下称"本网站"）。在您使用本服务前，请您认真、仔细阅读以下《免责及风险声明》；一经您访问浏览或使用本网站进行任何操作时，即视为您已全部知悉并同意声明下的全部内容并自愿受本声明。
      </p>
      <p class="common-tm">
        风险提示：本网站承诺提供资讯服务，内容仅供用户参考，不作为任何用户决策建议。同时，本平台不承诺用户获取收益，也不与用户约定分享收益或分担损失。市场有风险，投资需谨慎。
      </p>
      <p class="common-tm">
        本网站会尽可能全面、完整、准确地搜集和展示项目及可能与之相关的其他信息，用户须明白，本网站对所提供的内容力求准确、完整和及时，但并不对其准确性、完整性和及时性做出任何保证。但您请理解：
      </p>
      <p class="common-tm">
        这并不意味着本网站对其准确性、完整性和及时性做出任何保证，亦不对该等信息的错误或片面传递因此造成您的决策误判而承担任何责任。
      </p>
      <p class="common-tm">
        在本网站上提供的项目行情信息均不构成任何投资咨询、交易咨询或任何其他类型的建议，您不应将此等信息展示错误理解为任何投资或咨询类建议。
      </p>
      <p class="common-tm">
        本网站对其网站中第三方广告或超链接中任何第三方网站的任何信息、产品及业务及其他任何形式的不属于本网站主体的内容均不做任何保证、承诺或推荐；
      </p>
      <p class="common-tm">
        您在决定使用任何第三方服务之前，一定要自行做好对第三方的调查。若您因浏览、访问本网站相关信息或链接跳转到任何第三方网站使用其提供的任何服务、信息及产品而导致的任何损失，均为您个人决定，且应自行承担由此产生的一切责任。本网站均不对此承担任何责任。
      </p>
      <p class="common-tm">
        您理解并同意：为了向您提供更完善的服务，本网站有权定期或不定期地对提供本网站进行检修、维护、升级等，此类情况可能会造成相关服务在合理时间内中断或暂停。本平台不承担由此造成的任何责任。
      </p>
      <p class="common-tm">
        本网站保留本声明的最终解释权，并有权修订本声明及网站内容，并以网站公示的方式进行公告不再单独通知您的权利，变更后的内容会在网站公布更新后即刻生效。您应不时浏览申明变更时间及更新内容，如您不同意相关内容，应当立即停止使用本网站服务；若您继续使用本网站服务，即表示您接受并同意经修订的内容，自行承担由此产生的一切责任。
      </p>
    </div>

<!--    <div class="common-t">-->
<!--      <p style="margin-top: 30px; display: inline-block;text-align:left;width:100%;" class="cj">-->
<!--        帮助说明-->
<!--      </p>-->
<!--      <p class="common-tm">-->
<!--        《安装卸载》-->
<!--        https://www.yuque.com/u41395945/lmwlyi/abdgk4dw1tnt5dr8?singleDoc#-->


<!--      </p>-->
<!--      <p class="common-tm">-->
<!--        《海豹后台管理系统登陆》-->
<!--        https://www.yuque.com/u41395945/lmwlyi/kf4zxhf3ii0devlu?singleDoc#-->

<!--      </p>-->
<!--      <p class="common-tm">-->

<!--        《海豹APP客户端登录》-->
<!--        https://www.yuque.com/u41395945/lmwlyi/pcfwqzvfdarq095x?singleDoc#-->

<!--      </p>-->
<!--      <p class="common-tm">-->

<!--        《翻译说明》-->
<!--        https://www.yuque.com/u41395945/lmwlyi/zfm2va4x2smkplpd?singleDoc#-->

<!--      </p>-->
<!--      <p class="common-tm">-->

<!--        《快捷回复》-->
<!--        https://www.yuque.com/u41395945/lmwlyi/mpfzrdmu0o1rtp4l?singleDoc#-->

<!--      </p>-->
<!--      <p class="common-tm">-->
<!--        《更多设置》-->
<!--        https://www.yuque.com/u41395945/lmwlyi/ee927aqzs2z9osgt?singleDoc#-->

<!--      </p>-->
<!--      <p class="common-tm">-->
<!--        《注意事项》-->
<!--        https://www.yuque.com/u41395945/lmwlyi/mi3bkafadv70wn7g?singleDoc#-->
<!--      </p>-->

<!--    </div>-->
  </div>
</template>
<script>

import {getDicts} from "@/api/main/admin";
export default {
  data() {
    return {
      tgAccount: [],
    };
  },
  async created() {
    
    getDicts("tg_account").then(res => {
      this.tgAccount = res.data
    }).catch(error => {
    })
  },
  methods: {
    contachMe() {
      for (let i = 0; i < this.tgAccount.length; i++) {
        if (this.tgAccount[i].dictLabel == 'kefu') {
          window.open("https://t.me/"+this.tgAccount[i].dictValue);
          break;
        }
      }
    },
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
.common {
  width: 100%;
  background-color: #fff;
}
.common .common-t {
  background: #fff;
  border-radius: 28px;
  width: 50%;
  margin-left: 25%;
  padding-bottom: 5px;
  margin-bottom: 30px;
}
.common .common-t .common-tt {
  font-size: 35px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222a41;
  line-height: 50px;
  text-align: left;
  margin-left: 15%;
}
.common .common-t .common-tm {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #262930;
  line-height: 20px;
  text-align: left;
}
.common .common-t .common-tml {
  margin-left: -10%;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #252d43;
  line-height: 32px;
  font-weight: 700;
  margin: 20px 0 10px -10%;
}
.common .common-t .common-tmp {
  font-size: 14px;
  width: 80%;
  margin-left: -4%;
  color: #6d7795;
}
.cj {
  font-size: 40px;
  font-weight: 700;
}
@media (max-width: 991px) {
  .common-t {
    box-shadow: none !important;
    width: 100% !important;
  }
  .topI {
    display: none !important;
  }
  .common-t {
    margin-left: 0 !important;
    margin-top: 20px !important;
  }
  .cj {
    font-size: 21px;
    font-family: FZDHTJW--GB1-0, FZDHTJW--GB1;
    font-weight: 700;
    color: #252d43;
    line-height: 40px;
    text-align: left;
    margin-left: 15px;
    margin-right: 15px;
    display: flex;
    margin: 0 !important;
    margin-left: 10px !important;
        padding-left: 24px;
    padding-right: 24px;
  }
  .wI {
    widows: 26px !important;
    height: 26px !important;
  }
  .wIBox {
    margin: 0 !important;
  }
  .common-tt {
    align-items: center;
    justify-content: flex-start !important;
    margin-left: 15px !important;
  }
  .common-tm {
    margin-left: 15px !important;
    margin-top: 0px !important;
    font-size: 14px !important;
    padding-left: 24px;
    padding-right: 24px;
  }
  .mainBox {
    margin-left: 0px !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }
  .mainBoxItem {
    margin-left: 0px !important;
  }
  .common .common-t .common-tml {
    margin-left: 0px !important;
  }
  .common .common-t .common-tmp {
    margin-left: 0px !important;
  }
  .mainBoxItem {
    width: 82% !important;
    background: #fff;
    box-shadow: 0 0 30px 0 rgba(168, 225, 255, 0.5);
    border-radius: 8px;
    padding: 10px 20px;
    margin-bottom: 20px !important;
  }
  .common-t {
    position: relative !important;
    border-radius: 0px !important;
  }
  .common {
    height: auto !important;
  }
}
</style>