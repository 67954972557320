import Vue from 'vue'
import VueRouter from 'vue-router'
import login from '../views/login.vue'
import download from '../views/download.vue'
import price from '../views/price.vue'
import problem from '../views/problem.vue'
import mzsm from '../views/mzsm.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: login
  },
  {
    path: '/download',
    name: 'download',
    component: download
  },
  {
    path: '/price',
    name: 'price',
    component: price
  },
  {
    path: '/problem',
    name: 'problem',
    component: problem
  },
  {
    path: '/mzsm',
    name: 'mzsm',
    component: mzsm
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})




export default router
